/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { LocationMapMarker } from 'components/atoms/location-map-marker';
import { LocationMapMini } from 'components/molecules/location-map-mini';
import { LocationMapListItem } from 'components/atoms/location-map-list-item';
import { LocationMapList } from 'components/molecules/location-map-list';
import { LocationMapInfoCard, LocationMapInfoCardProps } from 'components/molecules/location-map-info-card';
import { Icon } from 'components/atoms/icon';
import { BottomSheet } from 'react-spring-bottom-sheet';

// import 'react-spring-bottom-sheet/dist/style.css';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const mapImg = '/images/map-lg.png';

export interface LocalizedString {
  cn?: string;
  th?: string;
}

export interface POIItemProps {
  x: number;
  y: number;
  spX?: number;
  spY?: number;
  action?: boolean;
  type?: ModifierProp<'poi' | 'bts' | 'mrt' | 'mrt-2' | 'mrt-circle'>;
  image?: string;
  name?: LocalizedString;
  depth?: number;
  value?: string;
  order?: number;
  spOnly?: boolean;
  cardInfo?: LocationMapInfoCardProps;
}

export interface LocationMapProps {
  poiList?: POIItemProps[];
  transports?: POIItemProps[];
  onPOIClick?: (value: string, active: boolean) => void;
}

interface LocationMapCardPosition {
  x: number;
  y: number;
}

export const LocationMap: React.FC<LocationMapProps> = ({ poiList, transports, onPOIClick }) => {
  const { i18n } = useTranslation();
  const coreMapRef = useRef(null);
  const infoCardRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [spPoiList, setSpPoiList] = useState([]);
  const [showInfoCard, setShowInfoCard] = useState(false);
  const [cardPosition, setCardPosition] = useState({ x: 0, y: 0 });
  const [activeCardInfo, setActiveCardInfo] = useState<LocationMapInfoCardProps>();

  const handleClick = useCallback(
    (index, active) => {
      if (active) {
        setActiveIndex(index);
        setShowInfoCard(true);
        const activeCardPosition: LocationMapCardPosition = {
          x: 0,
          y: 0,
        };
        // calculate card position
        if (poiList) {
          const activeItem = poiList[index];
          if (activeItem) {
            setActiveCardInfo(activeItem.cardInfo as any);
            // wait for rendering
            setTimeout(() => {
              const coreMap: HTMLElement = coreMapRef?.current as any;
              const infoCard: HTMLElement = infoCardRef?.current as any;
              let infoCardWidth = 456;
              let infoCardHeight = 620;
              const isTablet = window.innerWidth < 993;

              if (isTablet) {
                infoCardWidth = 326;
                infoCardHeight = 520;
              }

              if (coreMap && infoCard) {
                const coreMapRect = coreMap.getBoundingClientRect();
                // const infoCardRect = infoCard.getBoundingClientRect();
                activeCardPosition.y = (coreMapRect.height * activeItem.y) / 100 - 230;
                // check if we display card exceed map height or not
                if (activeCardPosition.y + infoCardHeight > coreMapRect.height) {
                  const yDiff = activeCardPosition.y + infoCardHeight - coreMapRect.height;
                  activeCardPosition.y -= yDiff;
                }

                if (activeCardPosition.y < 0) {
                  activeCardPosition.y = 0;
                }

                // coreMapRect.height
                if (activeItem.x > 50) {
                  // show card on the left
                  activeCardPosition.x = (coreMapRect.width * activeItem.x) / 100 - infoCardWidth - 100;
                  if (activeCardPosition.x < 0) {
                    activeCardPosition.x = 0;
                  }
                } else {
                  // show card on the right
                  activeCardPosition.x = (coreMapRect.width * activeItem.x) / 100 + 100;
                }
              }

              setCardPosition(activeCardPosition);
            }, 100);
          }
        }
      } else {
        setActiveIndex(null);
        setShowInfoCard(false);
      }
      if (onPOIClick) {
        // get value from current index
        if (poiList) {
          const item = poiList[index] as any;
          if (item) {
            onPOIClick(item.value, active);
          }
        }
      }
    },
    [onPOIClick, poiList]
  );

  const handleSPClick = useCallback(
    value => {
      // get current card item
      if (poiList) {
        const activeItem = poiList.filter(x => x.value === value).pop();
        if (activeItem) {
          const cardInfo = activeItem.cardInfo as any;
          setActiveCardInfo(cardInfo);
          setShowInfoCard(true);
        }
      }
      if (onPOIClick) {
        onPOIClick(value, true);
      }
    },
    [onPOIClick, poiList]
  );

  useEffect(() => {
    const spPoiList =
      poiList?.map((item, index) => {
        return {
          x: item.spX,
          y: item.spY,
          action: true,
          item: (
            <LocationMapListItem
              key={index}
              type="number"
              name={item.order?.toString() as string}
              value={item.value}
              onClick={value => handleSPClick(value)}
            />
          ),
        };
      }) || [];
    // update transport list for SP POI List
    transports
      ?.filter(item => item.spOnly)
      .forEach((item, index) => {
        spPoiList.push({
          x: item.x,
          y: item.y,
          action: false,
          item: <LocationMapListItem key={`t${index}`} type="logo" name={item.type as string} />,
        });
      });
    setSpPoiList(spPoiList as any);
  }, [poiList, transports, handleSPClick]);

  const sortPOIByOrder = (poiList: any) => {
    return poiList
      .map((x: any) => x)
      .sort((a: any, b: any) => {
        if (a.order < b.order) {
          return -1;
        } else if (a.order > b.order) {
          return 1;
        }
        return 0;
      });
  };

  const handleCloseInfoCard = useCallback(() => {
    setActiveIndex(null);
    setShowInfoCard(false);
  }, [setShowInfoCard]);

  const lang = useMemo(() => {
    return i18n.language || 'cn';
  }, [i18n.language]);

  return (
    <div className={mapModifiers('o-location-map', lang)}>
      <div className="o-location-map__large">
        <div className="o-location-map__map" ref={coreMapRef}>
          <img
            data-sizes="auto"
            data-src={mapImg as any}
            className="lazyload"
            alt="QSNCC map"
            width="1440"
            height="762"
          />
        </div>
        <div className="o-location-map__poi">
          <div className="o-location-map__poi-list">
            {poiList?.map((item, index) => (
              <div
                key={index}
                className={mapModifiers('o-location-map__poi-list-item', activeIndex === index && 'active')}
                style={{ top: `${item.y}%`, left: `${item.x}%`, zIndex: item.depth }}
              >
                <LocationMapMarker active={activeIndex === index} onClick={active => handleClick(index, active)}>
                  <img
                    data-src={item.image}
                    alt={(item.name as any)[lang]}
                    className="lazyload"
                    width="272"
                    height="272"
                  />
                </LocationMapMarker>
              </div>
            ))}
          </div>
          <div className="o-location-map__poi-transport">
            {transports
              ?.filter(item => !item.spOnly)
              .map((item, index) => (
                <div
                  key={index}
                  className={mapModifiers('o-location-map__poi-transport-item', item.type)}
                  style={{ top: `${item.y}%`, left: `${item.x}%` }}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="o-location-map__mini">
        <div className="o-location-map__mini-map">
          <LocationMapMini poiList={spPoiList as any} />
        </div>
        <div className="o-location-map__mini-map-list">
          <LocationMapList>
            {sortPOIByOrder(poiList)?.map((item: any, index: number) => (
              <LocationMapListItem
                key={index}
                type="number"
                name={item.order?.toString() as string}
                value={item.value}
                onClick={value => handleSPClick(value)}
              >
                {(item.name as any)[lang]}
              </LocationMapListItem>
            ))}
            {/*
            <LocationMapListItem type="logo" name="mrt-circle" value="mrt">
              MRT Station
            </LocationMapListItem>
            <LocationMapListItem type="logo" name="bts" value="bts">
              BTS Station
            </LocationMapListItem>
            */}
          </LocationMapList>
        </div>
      </div>
      <motion.div
        className={mapModifiers(
          'o-location-map__info-card',
          showInfoCard && 'active',
          activeCardInfo ? (activeCardInfo as any).key : undefined
        )}
        ref={infoCardRef}
        initial={{
          opacity: 0,
          y: -20,
          scale: 0.5,
        }}
        animate={{
          opacity: showInfoCard ? 1 : 0,
          y: showInfoCard ? 0 : -20,
          scale: showInfoCard ? 1 : 0.5,
        }}
        transition={{
          deplay: 1,
          duration: 0.5,
          ease: 'easeInOut',
        }}
        style={{
          top: cardPosition.y,
          left: cardPosition.x,
        }}
      >
        <button type="button" className="o-location-map__info-card__close" onClick={handleCloseInfoCard}>
          <Icon name="close" color="ivory" />
        </button>
        <div className="o-location-map__info-card__content">
          {activeCardInfo && (
            <LocationMapInfoCard
              logo={activeCardInfo.logo}
              image={activeCardInfo.image}
              imageSP={activeCardInfo.imageSP}
              imageWebp={activeCardInfo.imageWebp}
              imageWebpSP={activeCardInfo.imageWebpSP}
              title={activeCardInfo.title}
              description={activeCardInfo.description}
              tel={activeCardInfo.tel}
              hours={activeCardInfo.hours}
              map={activeCardInfo.map}
            />
          )}
        </div>
      </motion.div>
      <BottomSheet
        className={mapModifiers(
          'o-location-map__info-bottom-sheet',
          i18n.language,
          activeCardInfo ? (activeCardInfo as any).key : undefined
        )}
        open={showInfoCard}
        onDismiss={handleCloseInfoCard}
        // snapPoints={({ maxHeight }) => maxHeight * 0.8}
      >
        <button
          type="button"
          className={mapModifiers('o-location-map__info-bottom-sheet-close', activeCardInfo?.logo && 'logo')}
          onClick={() => {
            setShowInfoCard(false);
          }}
        >
          <Icon name="close" color="gold" />
        </button>
        {activeCardInfo && (
          <LocationMapInfoCard
            logo={activeCardInfo.logo}
            image={activeCardInfo.image}
            imageSP={activeCardInfo.imageSP}
            imageWebp={activeCardInfo.imageWebp}
            imageWebpSP={activeCardInfo.imageWebpSP}
            title={activeCardInfo.title}
            description={activeCardInfo.description}
            tel={activeCardInfo.tel}
            hours={activeCardInfo.hours}
            map={activeCardInfo.map}
          />
        )}
      </BottomSheet>
    </div>
  );
};
