import React from 'react';
import { mapModifiers } from 'libs/component';
import { LocationInfoItemProps } from 'components/atoms/location-info-item';

const mapImg = '/images/map-mobile.svg';

export interface POIItemProps {
  x: number;
  y: number;
  action?: boolean;
  item: React.ReactElement<LocationInfoItemProps>;
}

export interface LocationMapMiniProps {
  poiList: POIItemProps[];
}

export const LocationMapMini: React.FC<LocationMapMiniProps> = ({ poiList }) => (
  <div className={mapModifiers('m-location-map-mini')}>
    <div className="m-location-map-mini__map">
      <img data-sizes="auto" data-src={mapImg} alt="QSNCC map" className="lazyload" />
    </div>
    <div className="m-location-map-mini__poi-list">
      {poiList.map((poi, index) => (
        <div
          key={index}
          className={mapModifiers('m-location-map-mini__poi-item', poi.action && 'action')}
          style={{
            top: `${poi.y}%`,
            left: `${poi.x}%`,
          }}
        >
          {poi.item}
        </div>
      ))}
    </div>
  </div>
);
