import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export const LOGO_SHAPES = ['bmta', 'bts', 'mrt', 'mrt-circle', 'airport-raillink'];
export type IconShape = typeof LOGO_SHAPES[number];

export interface LogoProps {
  name: typeof LOGO_SHAPES[number];
  modifiers?: ModifierProp<'large'>;
  width?: number;
  height?: number;
}

export const Logo: React.FC<LogoProps> = ({ name, modifiers, width, height }) => (
  <i
    className={mapModifiers('a-logo', modifiers, name)}
    style={{
      width: width ? `${width}px` : undefined,
      height: height ? `${height}px` : undefined,
    }}
  />
);
