import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { useTranslation } from 'react-i18next';

export interface HeadingProps {
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  modifiers?: ModifierProp<'hero' | 'section' | 'welcome'>;
}

export const Heading: React.FC<HeadingProps> = ({ children, subtitle, modifiers }) => {
  const { i18n } = useTranslation();

  return (
    <div className={mapModifiers('a-heading', modifiers, i18n.language)}>
      <h1 className="a-heading__title">{children}</h1>
      {subtitle && <h2 className="a-heading__subtitle">{subtitle}</h2>}
    </div>
  );
};
