import React from 'react';
import { mapModifiers } from 'libs/component';

export interface LocationMapListProps {
  children?: React.ReactNode;
}

export const LocationMapList: React.FC<LocationMapListProps> = ({ children }) => (
  <div className={mapModifiers('m-location-map-list')}>{children}</div>
);
