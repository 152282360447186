/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { Icon } from 'components/atoms/icon';
import Link from 'next/link';

export interface ViewMoreProps {
  children?: React.ReactNode;
  link: any;
  color?: ModifierProp<'gold' | 'blue'>;
  target?: string;
}

export const ViewMore: React.FC<ViewMoreProps> = ({ children, link, color = 'gold', target }) => (
  <span className={mapModifiers('a-view-more', color)}>
    <Link href={link}>
      <a className="a-view-more__link" target={target} rel={target === '_blank' ? 'noreferrer' : undefined}>
        {children}
        <i className="a-view-more__icon">
          <Icon name="chevron-right" color={color as string} />
        </i>
      </a>
    </Link>
  </span>
);
