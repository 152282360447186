import React, { useCallback } from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

const arrow = '/images/arrow-down.svg';

export interface ShortcutItemProps {
  title: string;
  subtitle: string;
  onClick?: (event: React.MouseEvent) => void;
  modifiers?: ModifierProp<'active' | 'concept'>;
  scrollElement?: HTMLElement | string;
  offset?: number;
}

export const ShortcutItem: React.FC<ShortcutItemProps> = ({
  title,
  subtitle,
  onClick,
  scrollElement,
  modifiers,
  offset = 0,
}) => {
  const handleClick = useCallback(
    event => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const element: any = typeof scrollElement === 'string' ? document.querySelector(scrollElement) : scrollElement;
      if (element !== window) {
        const rect = element?.getBoundingClientRect();
        if (rect) {
          const bodyRect = document.body.getBoundingClientRect();
          const topPos = rect.top - bodyRect.top + offset;
          window.scrollTo({ top: topPos, left: 0, behavior: 'smooth' });
        }
      } else {
        element?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
      if (onClick) {
        onClick(event);
      }
    },
    [offset, onClick, scrollElement]
  );

  return (
    <div className={mapModifiers('a-shortcut-item', modifiers)}>
      <button className="a-shortcut-item__button" type="button" onClick={handleClick}>
        <h3 className="a-shortcut-item__title">{title}</h3>
        <p className="a-shortcut-item__subtitle">{subtitle}</p>
        {modifiers !== 'concept' && (
          <div className="a-shortcut-item__arrow">
            <img data-src={arrow} alt="click here" className="lazyload" width="14" height="20" />
          </div>
        )}
      </button>
    </div>
  );
};
