import React from 'react';
import { mapModifiers } from 'libs/component';
import { Tabs } from 'components/molecules/tabs';
import { Logo } from 'components/atoms/logo';
import { ViewMore } from 'components/atoms/view-more';
import { useTranslation } from 'react-i18next';

export const GettingHere: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={mapModifiers('o-getting-here', i18n.language)}>
      <Tabs
        current={0}
        tabs={[
          { icon: 'mrt', children: <span>{t('home.getting-here.mrt.title')}</span> },
          { icon: 'bts', children: <span>{t('home.getting-here.bts.title')}</span> },
          { icon: 'bus', children: <span>{t('home.getting-here.bus.title')}</span> },
          { icon: 'car', children: <span>{t('home.getting-here.car.title')}</span> },
          { icon: 'plane', children: <span>{t('home.getting-here.airport.title')}</span> },
          { icon: 'parking', children: <span>{t('home.getting-here.parking.title')}</span> },
        ]}
      >
        <div className="o-getting-here__item o-getting-here__item--mrt">
          <div className="o-getting-here__item-logo">
            <Logo name="mrt" modifiers="large" /> {t('home.getting-here.mrt.title')}
          </div>
          <div
            className="o-getting-here__item-text"
            dangerouslySetInnerHTML={{
              __html: t('home.getting-here.mrt.description'),
            }}
          />
        </div>
        <div className="o-getting-here__item o-getting-here__item--bts">
          <div className="o-getting-here__item-logo">
            <Logo name="bts" modifiers="large" /> {t('home.getting-here.bts.title')}
          </div>
          <div
            className="o-getting-here__item-text"
            dangerouslySetInnerHTML={{
              __html: t('home.getting-here.bts.description'),
            }}
          />
        </div>
        <div className="o-getting-here__item o-getting-here__item--bus">
          <div className="o-getting-here__item-logo">
            <Logo name="bmta" modifiers="large" /> {t('home.getting-here.bus.title')}
          </div>
          <div
            className="o-getting-here__item-text"
            dangerouslySetInnerHTML={{
              __html: t('home.getting-here.bus.description'),
            }}
          />
        </div>
        <div className="o-getting-here__item o-getting-here__item--car">
          <div
            className="o-getting-here__item-text"
            dangerouslySetInnerHTML={{
              __html: t('home.getting-here.car.description'),
            }}
          />
          <br />
          <br />
          <div className="o-getter-here__item-view-more">
            {/* <ViewMore link="https://goo.gl/maps/GhiF44dHGQDu1Bm29" target="_blank">
              {t('general.get-direction')}
            </ViewMore> */}
            <ViewMore link="https://map.baidu.com/poi/%E8%AF%97%E4%B8%BD%E5%90%89%E7%8E%8B%E5%90%8E%E5%9B%BD%E5%AE%B6%E4%BC%9A%E8%AE%AE%E4%B8%AD%E5%BF%83/@11194275.162236841,1533318.3482894737,13.99z?uid=0680a0e4b7af2058d12dd697&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&seckey=2e2a0a47bdcac477b497b3756246e88116d753808397929acaaf5a201554f1f535a8951ab937d219eff0ffe9855708944f6bcd8db2619f964c6113bb03b0ac033fbbfcc5712807b274dbe0e1865bdfc3ac1c147617dd3facfaffdc23745bf492f44a37ad8ff147baadfcdafc303c1e8b7aa6ae7514562d49f3062bb4f8ddaefb399d0e95f6244068788dd72c38a8aae1afd106f7b02075ee6b8117f672f5f14d905e5cee9816e18b2b72ab4f9f3b78d954b93ae3e18eacdfb16abf365586c7bfa7b30cf02691255c21d6b91f5776856a3107678826753cdd0d584000c9f7acd5bd45a237ed16732e94ee8466e06789bf&querytype=detailConInfo&da_src=shareurl" target="_blank">
              {t('general.get-direction')}
            </ViewMore>
          </div>
        </div>
        <div className="o-getting-here__item o-getting-here__item--airport">
          <div
            className="o-getting-here__item-text"
            dangerouslySetInnerHTML={{
              __html: t('home.getting-here.airport.description'),
            }}
          />
        </div>
        <div className="o-getting-here__item o-getting-here__item--parking">
          <div
            className="o-getting-here__item-text"
            dangerouslySetInnerHTML={{
              __html: t('home.getting-here.parking.description'),
            }}
          />
        </div>
      </Tabs>
    </div>
  );
};
