/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { mapModifiers } from 'libs/component';
import { GlobalHeader, GlobalHeaderItem, GlobalHeaderSocialItem } from 'components/organisms/global-header';
import Link from 'next/link';
import { SubscribeBox } from 'components/molecules/subscribe-box';
import { Whole } from 'components/organisms/whole';
import { GlobalFooter, GlobalFooterSocialItem } from 'components/organisms/global-footer';
import { BackToTopButton } from 'components/atoms/back-to-top-button';
import { withRouter, NextRouter } from 'next/router';
import { Waypoint } from 'react-waypoint';
import { Carousel } from 'components/organisms/carousel';
import { Heading } from 'components/atoms/heading';
import { Section } from 'components/molecules/section';
import { motion } from 'framer-motion';
import { ShortcutList } from 'components/molecules/shortcut-list';
import { ShortcutItem } from 'components/atoms/shortcut-item';
import { Text } from 'components/atoms/text';
import { LocationMap } from 'components/organisms/location-map';
import { ViewMore } from 'components/atoms/view-more';
import { ImageFrame } from 'components/atoms/image-frame';
import { Image as AImage } from 'components/atoms/image';
import { GettingHere } from 'components/organisms/getting-here';
// import { CardItem } from 'components/atoms/card-item';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { isRetina, isSafari } from 'libs/feature-detect';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Icon } from 'components/atoms/icon';
import { GLANCE_NEW_NEWCHAPTER, GLANCE_VISION_LIST, GLANCE_HISTORY_IMG_LIST, GLANCE_HISTORY_INFO, GLANCE_HISTORY_NEWCHAPTER, GLANCE_WHYQSNCC_IMG_LIST, GLANCE_WHYQSNCC, GLANCE_WHYQSNCC_LOCATION } from 'libs/constants';

import {
  API_KEY,
  HOME_GALLERY_LIST,
  LANGUAGE_OPTIONS,
  MAIN_NAV_ITEMS,
  NEWS_LIST,
  SOCIAL_ITEM_LIST,
  TAXIMAIL_API,
  VIDEO_BANNERS,
  VIDEO_SOURCES,
} from 'libs/constants';
import { POI_LIST } from 'libs/constants';

import scrollIconLottieFile from 'lotties/scroll-icon.json';
import { Modal } from 'components/organisms/modal';

import { Player, ControlBar, BigPlayButton } from 'video-react';
import { PageLoading } from 'components/atoms/page-loading';

import { Button } from 'components/atoms/button';
import { NewsCard } from 'components/atoms/news-card';


export interface HomeProps {
  router: NextRouter;
  title?: string;
  onNewsClick?: (slug: string) => void;
}

interface NavItemProps {
  label: string;
  link: string;
}

interface SocialItemProps {
  type: string;
  link: string;
}

interface VideoSourceProps {
  mp4: string;
  webm: string;
}

interface VideoSourceListProps {
  sp: VideoSourceProps;
  tl: VideoSourceProps;
  pc: VideoSourceProps;
}

// const glanceHistoryInfo = useMemo(() => {
//   return GLANCE_HISTORY_INFO.filter(x => (x.title as any)[i18n.language || 'en'] !== '');
// }, [i18n.language]);

const navList: NavItemProps[] = MAIN_NAV_ITEMS;

const languageOptions = LANGUAGE_OPTIONS;

const socialList: SocialItemProps[] = SOCIAL_ITEM_LIST;

/* const imageList: any[] = [
  {
    theme: 'dark',
    image: '/images/hero-banners/home/Dark_2880x1800_@2x.jpg',
    imageSp: '/images/hero-banners/home/Dark_2880x1800_@1x.jpg',
    caption: 'QSNCC at night',
  },
  {
    theme: 'light',
    image: '/images/hero-banners/home/Light_2880x1800_@2x.jpg',
    imageSp: '/images/hero-banners/home/Light_1440x900_@1x.jpg',
    caption: 'QSNCC',
  },
]; */

const galleryList: any[] = HOME_GALLERY_LIST;

const glanceHistoryInfo: any[] = GLANCE_HISTORY_INFO;

const glanceHistoryImgList: any[] = GLANCE_HISTORY_IMG_LIST;

const glanceHistoryNewChapter: any[] = GLANCE_HISTORY_NEWCHAPTER;

const glanceWhyQSNCCImgList: any[] = GLANCE_WHYQSNCC_IMG_LIST;

const glanceWhyQSNCC: any[] = GLANCE_WHYQSNCC;

const glanceWhyQSNCCLocation: any[] = GLANCE_WHYQSNCC_LOCATION;

const glanceVisionList: any[] = GLANCE_VISION_LIST;

const glanceNewList: any[] = GLANCE_NEW_NEWCHAPTER;

const videoPoster = '/images/video-cover.jpg';
// const videoPosterWebM = '/images/video-cover.jpg';

const videoBanners: VideoSourceListProps = VIDEO_BANNERS;
const videoSources: VideoSourceListProps = VIDEO_SOURCES;

const Home: React.FC<HomeProps> = ({ router, onNewsClick }) => {
  const { t, i18n } = useTranslation();
  // const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [isStartLoading, setIsStartLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  // const [language, setLanguage] = useState(i18n.language || 'en');
  // const [pageScrollTop, setPageScrollTop] = useState(0);
  const [showHero, setShowHero] = useState(true);
  const [isScrollable, setIsScrollable] = useState(false);
  // const [showWaypoint, setShowWaypoint] = useState(true);
  const [heroTheme, setHeroTheme] = useState('dark');
  // const [heroActiveIndex, setHeroActiveIndex] = useState(0);
  const [showIntro, setShowIntro] = useState(false);
  const [showProjectInfo, setShowProjectInfo] = useState(false);
  const [showProjectConcept, setShowProjectConcept] = useState(false);
  const [showProjectProgress, setShowProjectProgress] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [showGettingHere, setShowGettingHere] = useState(false);
  const [showGettingHereTab, setShowGettingHereTab] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [stickBackToTopButton, setStickBackToTopButton] = useState(false);
  // const [isFullScroll, setIsFullScroll] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [windowOrientation, setWindowOrientation] = useState('horizontal');
  const [currentVideoBanner, setCurrentVideoBanner] = useState<VideoSourceProps | null>(null);
  const [currentVideoSource, setCurrentVideoSource] = useState<VideoSourceProps | null>(null);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isRetinaBrowser, setIsRetinaBrowser] = useState(false);
  const [currentActiveSection, setCurrentActiveSection] = useState<string | null>(null);
  const [isBannerPlayed, setIsBannerPlayed] = useState(false);

  const globalHeaderRef = useRef(null);
  const globalHeaderHeight = useRef(0);
  const mainRef = useRef(null);
  const rootRef = useRef(null);
  const heroRef = useRef(null);
  const introRef = useRef(null);
  const projectInfoRef = useRef(null);
  const projectConceptRef = useRef(null);
  const projectProgressRef = useRef(null);
  const newsRef = useRef(null);
  const gettingHereRef = useRef(null);
  const gettingHereTabRef = useRef(null);
  const mapRef = useRef(null);
  const videoBannerRef = useRef(null);
  const videoPlayerRef = useRef(null);

  const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');

  const handleCloseThankyouModal = useCallback(() => {
    setShowSuccessModal(false);
  }, []);

  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
  }, []);

  const handleSubscribe = useCallback(async (submitData: any) => {
    const http = axios.create({
      baseURL: TAXIMAIL_API.BASE_URL,
    });
    try {
      submitData.auth = API_KEY;
      setIsSubscribeLoading(true);
      // login first
      const loginPayload = {
        auth: API_KEY,
        apiKey: TAXIMAIL_API.API_KEY,
        secretKey: TAXIMAIL_API.SECRET_KEY,
      };
      const loginResult = await http.post(TAXIMAIL_API.LOGIN, loginPayload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (loginResult.data) {
        const sessionId = loginResult.data.data.session_id;
        submitData.sessionId = sessionId;
        // subscribe to mailing list
        const subscribeResult = await http.post(TAXIMAIL_API.IMPORT_CONTACT, submitData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        setIsSubscribeLoading(false);
        // show thank you dialog
        if (subscribeResult) {
          // show modal
          setShowSuccessModal(true);
        }
      }
    } catch (e: any) {
      setIsSubscribeLoading(false);
      let errorMessage = e.message;
      if (e.response) {
        if (e.response.data.error) {
          if (e.response.data.error.err_msg) {
            errorMessage = e.response.data.error.err_msg;
          } else {
            errorMessage = e.response.data.error;
          }
        } else {
          errorMessage = e.response.data;
        }
      }
      setApiErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, []);

  const newsList = useMemo(() => {
    return NEWS_LIST;
  }, []);

  const performHideHero = useCallback(() => {
    // trigger showing stuffs
    setShowHero(false);
    setHeroTheme('light');
    setShowIntro(true);
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 500);
  }, []);

  const performShowHero = useCallback(() => {
    // trigger showing intro
    setShowHero(true);
    setShowIntro(false);
    // setIsFullScroll(false);
    // reset states
    setShowProjectInfo(false);
    setShowProjectConcept(false);
    setShowProjectProgress(false);
    setShowGallery(false);
    setShowNews(false);
    setShowGettingHere(false);
    setShowGettingHereTab(false);
    setShowMap(false);
    setHeroTheme('dark');
  }, []);

  // check desktop mouse wheel
  const onWindowWheel = useCallback(
    e => {
      if (showHero) {
        e.preventDefault();
      }
      if (showHero && e.deltaY > 1 && !isLoading) {
        performHideHero();
        setTimeout(() => {
          setIsScrollable(true);
        }, 1000);
      } else if (!showHero && e.deltaY < -1 && window.scrollY === 0) {
        performShowHero();
        setIsScrollable(false);
      }
    },
    [isLoading, performHideHero, performShowHero, showHero]
  );
  useEffect(() => {
    window.addEventListener('wheel', onWindowWheel, { passive: false });
    return () => {
      window.removeEventListener('wheel', onWindowWheel);
    };
  }, [onWindowWheel]);

  // check swipe
  const [swipeYDown, setSwipeYDown] = useState(null);
  const onTouchStart = useCallback(e => {
    setSwipeYDown(e.targetTouches[0].clientY);
  }, []);
  const onTouchMove = useCallback(
    e => {
      if (swipeYDown !== null) {
        const swipeYUp = e.touches[0].clientY;
        const yDiff = Number(swipeYDown) - swipeYUp;
        if (yDiff > 0) {
          // down swipe
          if (showHero && yDiff > 1 && !isLoading) {
            performHideHero();
            setTimeout(() => {
              setIsScrollable(true);
            }, 1000);
          }
        } else {
          // up swipe
          if (!showHero && yDiff < -1 && window.scrollY === 0) {
            performShowHero();
            setIsScrollable(false);
          }
        }
        setSwipeYDown(null);
      }
    },
    [isLoading, performHideHero, performShowHero, showHero, swipeYDown]
  );
  useEffect(() => {
    if (document) {
      document.addEventListener('touchstart', onTouchStart);
      document.addEventListener('touchmove', onTouchMove);
    }
    return () => {
      if (document) {
        document.removeEventListener('touchstart', onTouchStart);
        document.removeEventListener('touchmove', onTouchMove);
      }
    };
  }, [onTouchMove, onTouchStart]);

  useEffect(() => {
    if (videoPlayerRef?.current) {
      const { player } = (videoPlayerRef?.current as any).getState();
      if (player.readyState === 4) {
        setIsBannerPlayed(true);
      }
    }
  }, []);

  const onWindowResize = useCallback(() => {
    const width = window.innerWidth || 0;
    const height = window.innerHeight || 0;
    const ratio = width / height;
    const baseRatio = 16 / 9;

    if (ratio >= baseRatio) {
      setWindowOrientation('horizontal');
    } else {
      setWindowOrientation('vertical');
    }
    let key = 'sp';
    if (width <= 480) {
      key = 'sp';
      if (isRetina()) {
        key = 'tl';
      }
    } else if (width <= 1024) {
      key = 'tl';
      if (isRetina()) {
        key = 'pc';
      }
    } else {
      key = 'pc';
    }
    let bannerKey = 'pc';
    if (height <= 720) {
      bannerKey = 'tl';
      if (isRetina()) {
        bannerKey = 'pc';
      }
    } else if (height <= 480) {
      bannerKey = 'sp';
      if (isRetina()) {
        bannerKey = 'tl';
      }
    }
    const targetVideoBanner = (videoBanners as any)[bannerKey] as any;
    const targetVideoSource = (videoSources as any)[key] as any;

    if (currentVideoBanner !== targetVideoBanner) {
      setCurrentVideoBanner(targetVideoBanner);
      const player = videoBannerRef?.current as any;
      if (player) {
        player.load();
        player.play();
      }
    }
    if (currentVideoSource !== targetVideoSource) {
      setCurrentVideoSource(targetVideoSource);
      const player = videoPlayerRef?.current as any;
      if (player) {
        player.load();
      }
    }
  }, [currentVideoBanner, currentVideoSource]);

  /* useEffect(() => {
    if (currentVideoBanner) {
      const player = videoBannerRef?.current as any;
      if (player) {
        player.load();
        player.play();
      }
    }
  }, [currentVideoBanner]); */

  const initHome = useCallback(() => {
    if (!isStartLoading) {
      setIsStartLoading(true);
    } else {
      return;
    }
    const isMobile = window.innerWidth <= 640;
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // setHeroActiveIndex(0);

    let imageLoadedCount = 0;
    // start load all resources in HOME
    // load all images
    const imageList = [
      '/images/video-thumbnail.jpg',
      '/images/qsncc_front_perspective.png',
      '/images/building-wireframe.svg',
      '/images/dummy/building-1.jpg',
      '/images/marker.svg',
      videoPoster,
      // videoPosterWebM,
    ];
    galleryList.forEach(item => {
      imageList.push(item.imageSp);
      imageList.push(item.image);
      imageList.push(item.imageWebpSp);
      imageList.push(item.imageWebp);
    });
    newsList.forEach((item: any) => {
      if (isMobile) {
        imageList.push(item.imageSp);
      } else {
        imageList.push(item.image);
      }
    });
    // Map
    if (isMobile) {
      imageList.push('/images/map-mobile.svg');
    } else {
      imageList.push('/images/map-lg.png');
    }
    POI_LIST.poiList.forEach((item: any) => {
      imageList.push(item.image);
      if (item.cardInfo.logo) {
        imageList.push(item.cardInfo.logo);
      }
      if (isMobile) {
        item.cardInfo.imageSP;
      } else {
        item.cardInfo.image;
      }
    });
    const imageLoaded = () => {
      imageLoadedCount += 1;
      const progress = Math.ceil((imageLoadedCount / imageList.length) * 100);
      setLoadingProgress(progress);
      // console.log('imageLoaded', imageLoadedCount, '/', imageList.length, imageLoadedCount === imageList.length);
      if (imageLoadedCount >= imageList.length) {
        setIsLoading(false);
      }
    };
    imageList.forEach(url => {
      // console.log('load:', url, index);
      const img = new Image();
      img.src = url;
      img.onload = imageLoaded;
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, [isStartLoading, newsList]);

  useEffect(() => {
    initHome();
  }, [initHome]);

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', onWindowResize);
    }
    onWindowResize();

    return () => {
      if (window) {
        window.removeEventListener('resize', onWindowResize);
      }
    };
  }, [onWindowResize]);

  const language = useMemo(() => {
    return i18n.language || 'cn';
  }, [i18n.language]);

  const onLanguageChange: any = useCallback(
    (language: string) => {
      // router.push(`/${language}`);
      if (language !== "/") {
        router.push(`${language}`);
      }
    },
    [router]
  );

  const isActiveNav = (link: string) => {
    const currentPath = router?.asPath;
    if (currentPath) {
      // const urlParts = currentPath.replace(`/${language}/`, '/').split('/');
      // const linkKey = link.replace(`/${language}/`, '/').substring(1);
      const urlParts = currentPath.replace(`/${language}/`, '/').split('/');
      const linkKey = link.replace(`/${language}/`, '/').substring(1);

      // check root path
      if (urlParts[1] === linkKey) return true;
    }
    return false;
  };

  const getNavLink = (link: string) => {
    if (link === '/') {
      return `/${language}`;
    }
    return `/${language}${link}`;
  };

  // const animateOut = useCallback(()=>{

  // },[])
  /* const handleScreenScrollEnter = useCallback(
    (e: any) => {
      // check position & direction
      if (e.currentPosition === 'inside') {
        // const heroEl: HTMLElement = heroRef?.current as any;
        // const mainEl: HTMLElement = mainRef?.current as any;

        // !! HIDE HERO !!
        if (e.previousPosition === 'below') {
          if (isFullScroll) return;
          // going in from top (scroll down inside)
          // scroll to main content
          // mainEl.style.marginTop = '0';
          // mainEl.style.transform = `translate3d(0, 0, 0)`;
          // heroEl.style.maxHeight = '0';
          setShowHero(false);
          setHeroTheme('light');
          setShowIntro(true);
          window.scrollTo({ top: 90, left: 0, behavior: 'smooth' });
        }
        // !! SHOW HERO !!
        else if (e.previousPosition === 'above') {
          const shouldForceScroll = !isFullScroll;
          // going in from bottom (scroll up inside)
          // scroll back to top
          // mainEl.style.marginTop = '100vh';
          // mainEl.style.transform = 'translate3d(0, 100vh, 0)';
          // heroEl.style.maxHeight = '100vh';
          setShowHero(true);
          setShowIntro(false);
          setIsFullScroll(false);
          setShowWaypoint(false);
          // reset states
          setShowProjectInfo(false);
          setShowProjectConcept(false);
          setShowProjectProgress(false);
          setShowGallery(false);
          setShowNews(false);
          setShowGettingHere(false);
          setShowGettingHereTab(false);
          setShowMap(false);
          const item = imageList[heroActiveIndex] as any;
          if (item) {
            setHeroTheme(item.theme);
          }
          setTimeout(() => {
            setShowWaypoint(true);
          }, 700);
          if (shouldForceScroll) {
            setTimeout(() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 150);
          }
        }
      }
    },
    [isFullScroll, heroActiveIndex]
  ); */
  /* const handleScreenScrollLeave = useCallback((e: any) => {
    if (e.currentPosition === 'above') {
      // we scroll down passed the waypoint already
    } else {
      // we scroll up passed the waypoint already
    }
  }, []); */

  /* const handleSlideChange = useCallback(
    (instance: any) => {
      const activeIndex = instance.realIndex;
      const item = imageList[activeIndex] as any;
      setHeroActiveIndex(activeIndex);
      setHeroTheme(item.theme);
    },
    [setHeroTheme]
  ); */

  /* useEffect(() => {
    const item = imageList[0];
    setHeroTheme(item.theme);
  }, [setHeroTheme]); */

  const motionVariants = {
    headContainer: {
      hidden: {
        opacity: 0,
        y: -20,
      },
      show: {
        opacity: !showHero ? 1 : 0,
        y: !showHero ? 0 : -20,
        transition: {
          delay: 0.25,
          delayChildren: 0.25,
          duration: 0.5,
          ease: 'easeInOut',
          staggerChildren: 0.25,
        },
      },
    },
    headItem: {
      hidden: { opacity: 0 },
      show: { opacity: !showHero ? 1 : 0 },
    },
    projectInfo: {
      hidden: { opacity: 0, y: -20 },
      show: {
        opacity: showProjectInfo ? 1 : 0,
        y: 0,
        transition: {
          delay: 0.25,
          delayChildren: 0.25,
          duration: 0.5,
          ease: 'easeOut',
          staggerChildren: 0.25,
        },
      },
    },
    projectInfoItem: {
      hidden: { opacity: 0 },
      show: { opacity: showProjectInfo ? 1 : 0 },
    },
    projectConcept: {
      hidden: { opacity: 0, y: -20 },
      show: {
        opacity: showProjectConcept ? 1 : 0,
        y: 0,
        transition: {
          delay: 0.25,
          delayChildren: 0.25,
          duration: 0.5,
          ease: 'easeOut',
          staggerChildren: 0.25,
        },
      },
    },
    projectConceptItem: {
      hidden: { opacity: 0 },
      show: { opacity: showProjectConcept ? 1 : 0 },
    },
    projectProgress: {
      hidden: { opacity: 0, y: -20 },
      show: {
        opacity: showProjectProgress ? 1 : 0,
        y: 0,
        transition: {
          delay: 0.25,
          duration: 0.5,
          ease: 'easeOut',
        },
      },
    },
    gallery: {
      hidden: { opacity: 0, y: -20 },
      show: {
        opacity: showGallery ? 1 : 0,
        y: 0,
        transition: {
          delay: 0.5,
          duration: 1.5,
          ease: 'easeOut',
        },
      },
    },
    news: {
      hidden: { opacity: 0, y: -20 },
      show: {
        opacity: showNews ? 1 : 0,
        y: 0,
        transition: {
          delay: 0.25,
          delayChildren: 0.25,
          duration: 0.5,
          ease: 'easeOut',
          staggerChildren: 0.25,
        },
      },
    },
    newsItem: {
      hidden: { opacity: 0 },
      show: { opacity: !showHero ? 1 : 0 },
    },
    gettingHere: {
      hidden: { opacity: 0, y: -20 },
      show: {
        opacity: showGettingHere ? 1 : 0,
        y: 0,
        transition: {
          delay: 0.25,
          delayChildren: 0.25,
          duration: 0.5,
          ease: 'easeOut',
          staggerChildren: 0.25,
        },
      },
    },
    gettingHereItem: {
      hidden: { opacity: 0 },
      show: { opacity: !showHero ? 1 : 0 },
    },
    gettingHereTab: {
      hidden: { opacity: 0, y: -20 },
      show: {
        opacity: showGettingHereTab ? 1 : 0,
        y: 0,
        transition: {
          delay: 0.25,
          duration: 0.5,
          ease: 'easeOut',
        },
      },
    },
    map: {
      hidden: { opacity: 0, y: -20 },
      show: {
        opacity: showMap ? 1 : 0,
        y: 0,
        transition: {
          delay: 0.25,
          duration: 0.5,
          ease: 'easeOut',
        },
      },
    },
    backToTop: {
      hidden: { opacity: 0 },
      show: {
        opacity: !showHero ? 1 : 0,
        transition: {
          delay: 0.5,
          duration: 0.5,
          ease: 'easeInOut',
        },
      },
    },
    advertisingModal: {
      hidden: { opacity: 0, y: 50 },
      show: {
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.25,
          duration: 1.5,
          ease: 'easeInOut',
        }
      }
    }
  };

  const globalHeaderElement: HTMLElement = globalHeaderRef?.current as any;
  if (globalHeaderElement) {
    const rect = globalHeaderElement.getBoundingClientRect();
    globalHeaderHeight.current = rect.height;
  }

  // const handleVideoThumbnailClick = useCallback(() => {
  //   setShowVideoModal(true);
  //   const videoPlayer = videoPlayerRef?.current as any;
  //   videoPlayer.play();
  // }, []);

  const handleCloseVideoModal = useCallback(() => {
    const videoPlayer = videoPlayerRef?.current as any;
    videoPlayer.pause();
    setShowVideoModal(false);
  }, []);

  const poiList = POI_LIST.poiList;
  const transportList = POI_LIST.transportList;

  const handleSectionScrollEnter = useCallback((section: string) => {
    setCurrentActiveSection(section);

    // const bodyRect = document.body.getBoundingClientRect();
    // let offset = -globalHeaderHeight.current;
    // let element: HTMLElement = undefined as any;
    setShowIntro(false);

    if (section === 'intro') {
      setShowIntro(true);
      // element = introRef?.current as any;
    } else if (section === 'project-info') {
      setShowProjectInfo(true);
      // element = projectInfoRef?.current as any;
    } else if (section === 'project-concept') {
      setShowProjectConcept(true);
      // element = projectConceptRef?.current as any;
      // offset -= 40;
    } else if (section === 'project-construction') {
      setShowProjectProgress(true);
      // element = projectProgressRef?.current as any;
      // offset -= 40;
    } else if (section === 'gallery') {
      setShowGallery(true);
      // element = galleryRef?.current as any;
      // offset -= 40;
    } else if (section === 'news') {
      setShowNews(true);
      // element = newsRef?.current as any;
    } else if (section === 'getting-here') {
      setShowGettingHere(true);
      // element = gettingHereRef?.current as any;
    } else if (section === 'getting-here-tabs') {
      setShowGettingHereTab(true);
      // element = gettingHereTabRef?.current as any;
    } else if (section === 'map') {
      setShowMap(true);
      // element = mapRef?.current as any;
    }

    /*
      if (element && !isFullScroll && section !== 'intro') {
        const rect = element?.getBoundingClientRect();
        if (rect) {
          const topPos = rect.top - bodyRect.top + offset;
          window.scrollTo({ top: topPos, left: 0, behavior: 'smooth' });
        }
      } */
  }, []);

  const handleScrollIconClick = useCallback(() => {
    performHideHero();
    setTimeout(() => {
      setIsScrollable(true);
    }, 1000);
  }, [performHideHero]);

  const handleBackToTopButton = useCallback(() => {
    // setIsFullScroll(true);
    if (window.scrollY === 0 && !showHero) {
      performShowHero();
      setIsScrollable(false);
    }
  }, [performShowHero, showHero]);

  /* const handleShortcutItemClick = useCallback(() => {
    setIsFullScroll(true);
    setTimeout(() => {
      setIsFullScroll(false);
    }, 1000);
  }, []); */

  // const gallerySliderMoveHandler = useCallback((inst: any) => {
  /* const diff = inst.touches.diff;
    let movePos = diff / 10;
    if (movePos > 40) {
      movePos = 40;
    } else if (movePos < -40) {
      movePos = -40;
    }
    // move all images
    const galleryHolderEl: HTMLElement = galleryRef?.current as any;
    if (galleryHolderEl) {
      const imgList = galleryHolderEl.querySelectorAll('.swiper-slide img');
      imgList.forEach((el: any) => {
        // el.style.transition = 'none';
        el.style.transform = `translate3d(${movePos}px, 0, 0)`;
      });
    } */
  // }, []);



  const handleNewsClick = useCallback(
    (slug: string) => {
      if (onNewsClick) {
        onNewsClick(slug);
      }
    },
    [onNewsClick]
  );

  // const constructionProgress = useMemo(() => {
  //   const constructionEndDateObject = moment(constructionEndDate).tz(TIMEZONE);
  //   const now = moment().tz(TIMEZONE);
  //   const nowDateDiff = constructionEndDateObject.diff(now, 'days');
  //   const baseDateCount = 990;
  //   const progressDateDiff = baseDateCount - nowDateDiff;
  //   let progress = Math.ceil((progressDateDiff / baseDateCount) * 100) - 22;
  //   if (progress > 100) {
  //     progress = 100;
  //   }
  //   return progress;
  // }, []);

  useEffect(() => {
    setIsSafariBrowser(isSafari());
    setIsRetinaBrowser(isRetina());
  }, []);

  return (
    <div
      ref={rootRef}
      className={mapModifiers(
        't-home',
        language,
        isLoading && 'loading',
        showHero && 'hero',
        isScrollable && 'scrollable',
        isSafariBrowser && 'safari',
        isRetinaBrowser && 'retina',
        currentActiveSection && `section-${currentActiveSection}`,
        isBannerPlayed && 'banner-played',
      )}
    >
      {isLoading && <PageLoading animated={isStartLoading} progress={loadingProgress} />}
      {/* HEADER */}
      <GlobalHeader
        ref={globalHeaderRef}
        modifiers={showHero ? 'transparent' : undefined}
        socials={
          showHero
            ? socialList.map((socialItem, key) => (
              <GlobalHeaderSocialItem key={key} type={socialItem.type as any} link={socialItem.link} />
            ))
            : undefined
        }
        color={showHero ? (heroTheme as any) : 'light'}
        defaultLanguage={language}
        languageOptions={languageOptions}
        onLanguageChange={onLanguageChange}
      >
        {navList.map((navItem, index) => (
          <GlobalHeaderItem
            key={index}
            active={isActiveNav(`${getNavLink(navItem.link)}`)}
            component={
              <Link href={`${getNavLink(navItem.link)}`} locale={false}>
                <a>{navItem.label}</a>
              </Link>
            }
          />
        ))}
      </GlobalHeader>
      {/* /HEADER */}

      {/*
      {showWaypoint && (
        <div className={mapModifiers('t-home__waypoint', !showHero && 'active')}>
          <Waypoint onEnter={handleScreenScrollEnter} onLeave={handleScreenScrollLeave} />
        </div>
      )}
      */}

      {/* HERO BANNER */}
      <section
        ref={heroRef}
        className={mapModifiers(
          't-home__hero-banner',
          !showHero && 'hide',
          !showHero && !showIntro && 'hidden',
          windowOrientation
        )}
      >
        <div className="t-home__hero-banner-carousel">
          {/*
          <Carousel mode="hero" modifiers="no-button" onSlideChange={handleSlideChange}>
            {imageList.map((item, index) => (
              <motion.picture
                key={index}
                initial={{ scale: 1.5 }}
                animate={{ scale: 1.2 }}
                transition={{ repeat: Infinity, repeatType: 'reverse', duration: 10 }}
              >
                <source media="(max-width: 640px)" srcSet={item.imageSp} />
                <source media="(min-width: 640px)" srcSet={item.image} />
                <img src={item.imageSp} alt={item.caption} />
              </motion.picture>
            ))}
          </Carousel>
          */}
          {currentVideoBanner && (
            <Player
              ref={videoBannerRef}
              muted={true}
              playsInline={true}
              aspectRatio={'16:9'}
              autoPlay={true}
              loop={true}
              preload="none"
            >
              <ControlBar disableCompletely={true} />
              <source src={currentVideoBanner.webm} type="video/webm" />
              <source src={currentVideoBanner.mp4} type="video/mp4" />
            </Player>
          )}
        </div>
        <div className="t-home__hero-banner-heading">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: showHero ? 1 : 0, y: showHero ? 0 : 50 }}
            transition={{ delay: 0.25, duration: 1.5 }}
          >
            <Heading modifiers="hero" subtitle={t('home.hero-banner.subtitle')}>
              <span
                className="t-home__hero-banner-heading-text"
                dangerouslySetInnerHTML={{
                  __html: t('home.hero-banner.title'),
                }}
              />
            </Heading>
          </motion.div>
        </div>
        <div className="t-home__hero-banner-scroll-icon-holder" onClick={handleScrollIconClick}>
          <motion.div
            initial={{ opacity: 0, y: -50, scale: 1.1 }}
            animate={{
              opacity: showHero ? 1 : 0,
              y: showHero ? 0 : -50,
              scale: showHero ? 1 : 1.1,
            }}
            transition={{ delay: 0.75, duration: 1.5 }}
          >
            <div className="t-home__hero-banner-scroll-icon">
              {/* <img src="/images/mouse.svg" width="11" height="24" alt="scroll icon" /> */}
              {/*<img src="/images/icon-scroll-down.gif" width="44" height="44" alt="scroll icon" />*/}

              <Lottie animationData={scrollIconLottieFile} />
            </div>
          </motion.div>
        </div>
      </section>
      {/* /HERO BANNER */}

      {/* CONTENT */}
      <main ref={mainRef} className={mapModifiers('t-home__main', showHero && 'hero')}>
        <Whole className={mapModifiers('t-home__whole', showIntro && 'no-bg')}>
          <Section className="t-home__head">
            <motion.div ref={introRef} variants={motionVariants.headContainer} initial="hidden" animate="show">
              <motion.div className="t-home__head-heading" variants={motionVariants.headItem}>
                <Heading modifiers="welcome" subtitle={t('home.hero-banner.subtitle')}>
                  <span
                    className="t-home__hero-banner-heading-text"
                    dangerouslySetInnerHTML={{
                      __html: t('home.hero-banner.title'),
                    }}
                  />
                </Heading>
              </motion.div>

              <motion.p
                className="t-home__head-text t-home__head-text--line-1"
                variants={motionVariants.headItem}
                dangerouslySetInnerHTML={{
                  __html: t('home.hero-banner.description-line-1'),
                }}
              />

              <motion.p
                className="t-home__head-text t-home__head-text--line-2"
                variants={motionVariants.headItem}
                dangerouslySetInnerHTML={{
                  __html: t('home.hero-banner.description-line-2'),
                }}
              />

              <motion.div className="t-home__shortcuts" variants={motionVariants.headItem}>
                <ShortcutList>
                  <ShortcutItem
                    title={t('home.shortcuts.project-info.title')}
                    subtitle={t('home.shortcuts.project-info.subtitle')}
                    scrollElement={projectInfoRef?.current as any}
                    offset={-globalHeaderHeight.current}
                  // onClick={handleShortcutItemClick}
                  />
                  <ShortcutItem
                    title={t('home.shortcuts.news.title')}
                    subtitle={t('home.shortcuts.news.subtitle')}
                    scrollElement={newsRef?.current as any}
                    offset={-globalHeaderHeight.current}
                  // onClick={handleShortcutItemClick}
                  />
                  <ShortcutItem
                    title={t('home.shortcuts.getting-here.title')}
                    subtitle={t('home.shortcuts.getting-here.subtitle')}
                    scrollElement={gettingHereRef?.current as any}
                    offset={-globalHeaderHeight.current}
                  // onClick={handleShortcutItemClick}
                  />
                </ShortcutList>
              </motion.div>
            </motion.div>
            <Waypoint onEnter={() => handleSectionScrollEnter('intro')} />
          </Section>

          <div className={mapModifiers('t-home__inner-content', showIntro && 'force-bg')}>
            <Section className="t-home__project-info">
              {/*<Waypoint onEnter={() => setShowProjectInfo(true)} />*/}
              <div className="t-home__project-info-waypoint">
                <Waypoint onEnter={() => handleSectionScrollEnter('project-info')} />
              </div>
              <motion.div
                ref={projectInfoRef}
                className="t-home__project-info-content"
                variants={motionVariants.projectInfo}
                initial="hidden"
                animate="show"
              >
                <motion.div variants={motionVariants.projectInfoItem}>
                  <Heading>

                    {t('home.project-info.title.line-2')}
                  </Heading>
                </motion.div>


              </motion.div>
            </Section>

            <Section className="t-privacy-policy__detail">
              <div className="t-privacy-policy__info">
                {glanceVisionList.map((policy: any, index: number) => (
                  <div key={index} className="t-privacy-policy__info-detail-holder">
                    <div
                      className="t-privacy-policy__info-detail"
                      dangerouslySetInnerHTML={{ __html: (policy.content as any)[i18n.language || 'en'] }}
                    />
                  </div>
                ))}
              </div>
            </Section>


            <Section className="t-home__project-concept">
              <div className="t-home__project-concept-waypoint">
                <Waypoint onEnter={() => setShowProjectConcept(true)} />
              </div>
              {/*<Waypoint onEnter={() => handleSectionScrollEnter('project-concept')} />*/}
              <motion.div
                ref={projectConceptRef}
                variants={motionVariants.projectConcept}
                initial="hidden"
                animate="show"
              >
                {/* <ProjectConcept>
                  <motion.div variants={motionVariants.projectConceptItem}>
                    <ProjectConceptItem
                      icon={<Icon name="continue" color="gold" />}
                      title={t('home.project-concept.continue.title')}
                      subtitle={t('home.project-concept.continue.subtitle')}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('home.project-concept.continue.description'),
                        }}
                      />
                    </ProjectConceptItem>
                  </motion.div>
                  <motion.div variants={motionVariants.projectConceptItem}>
                    <ProjectConceptItem
                      icon={<Icon name="preserve" color="gold" />}
                      title={t('home.project-concept.preserving.title')}
                      subtitle={t('home.project-concept.preserving.subtitle')}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('home.project-concept.preserving.description'),
                        }}
                      />
                    </ProjectConceptItem>
                  </motion.div>
                  <motion.div variants={motionVariants.projectConceptItem}>
                    <ProjectConceptItem
                      icon={<Icon name="build" color="gold" />}
                      title={t('home.project-concept.building.title')}
                      subtitle={t('home.project-concept.building.subtitle')}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('home.project-concept.building.description'),
                        }}
                      />
                    </ProjectConceptItem>
                  </motion.div>
                </ProjectConcept> */}
              </motion.div>
            </Section>

            <Section className="t-home__project-progress bottomheight">
              <div className="t-home__project-progress bottomheight">
                <Waypoint onEnter={() => setShowProjectProgress(true)} />
              </div>
              {/*<Waypoint onEnter={() => handleSectionScrollEnter('project-construction')} />*/}
              <motion.div
                ref={projectProgressRef}
                variants={motionVariants.projectProgress}
                initial="hidden"
                animate="show"
              >
                {/* <ContructionProgress progress={constructionProgress} date={moment('2022-09-01').toDate()} /> */}
              </motion.div>
            </Section>

            {/* 历史 */}
            <Section className="t-privacy-policy__detail ">
              <div className="t-privacy-policy__info" >
                {glanceHistoryInfo.map((policy: any, index: number) => (
                  <div key={index} className="t-privacy-policy__info-detail-holder ">
                    {/* <div
                      className="t-privacy-policy__info-detail history"
                      dangerouslySetInnerHTML={{ __html: (policy.content as any)[i18n.language || 'en'] }}
                    /> */}
                    <motion.div
                      className="t-privacy-policy__info-detail history"
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity:  1, y: 0 }}
                      transition={{ delay: 0.25, duration: 1.5 }}
                      dangerouslySetInnerHTML={{ __html: (policy.content as any)[i18n.language || 'en'] }}
                    />
                  </div>
                ))}
              </div>
            </Section>

            {/* 图片轮播样式 */}
            <div className="t-media__info-news-carousel">
              <Carousel
                mode="gallery"
                modifiers="no-button"
              >
                {glanceHistoryImgList.map((item: any, index: number) => (
                  <div key={index} className="t-home__gallery-item history_img" data-attribute="SRL">
                    <AImage
                      src={item.image as any}
                      srcSp={item.imageSp as any}
                      srcWebp={item.imageWebp as any}
                      srcWebpSp={item.imageWebpSp as any}
                      caption={item.caption}
                      align={item.align}
                      lazy={true}
                    />
                  </div>
                ))}
              </Carousel>
            </div>

            {/* 新篇章 */}
            <div className=' t-title'><strong>新篇章</strong></div>
            <Section className="t-privacy-policy__detail newcapter " >
              <div className="p-about-glance__now-2022__compare-slider t-privacy-policy__info">
                <ReactCompareSlider
                  changePositionOnHover
                  handle={
                    <div className="p-about-glance__now-2022__handler">
                      <Icon name="chevron-left" color="white" />
                      <Icon name="chevron-right" color="white" />
                    </div>
                  }
                  itemOne={<ReactCompareSliderImage src="/images/glance/history/now-2022-01.jpg" alt="Previous" />}
                  itemTwo={<ReactCompareSliderImage src="/images/glance/history/now-2022-02.jpg" alt="Current" />}
                />
              </div>
            </Section>
            <Section className="t-privacy-policy__detail newcapter" >
              <div className="t-privacy-policy__info" >
                {glanceNewList.map((policy: any, index: number) => (
                  <div key={index} className="t-privacy-policy__info-detail-holder">
                    <div
                      className="t-privacy-policy__info-detail"
                      dangerouslySetInnerHTML={{ __html: (policy.content as any)[i18n.language || 'en'] }}
                    />
                  </div>
                ))}
              </div>
            </Section>


            {/* 文化遗产 */}
            <Section className="t-privacy-policy__detail newcapter" >
              <div className="t-privacy-policy__info" >
                {glanceHistoryNewChapter.map((policy: any, index: number) => (
                  <div key={index} className="t-privacy-policy__info-detail-holder">
                    <div
                      className="t-privacy-policy__info-detail"
                      dangerouslySetInnerHTML={{ __html: (policy.content as any)[i18n.language || 'en'] }}
                    />
                  </div>
                ))}
              </div>
            </Section>


            <div className='title'>不止于会奖 不止于场地</div>

            <Carousel
              mode="gallery"
              modifiers="no-button"
            >
              {glanceWhyQSNCCImgList.map((item: any, index: number) => (
                <div key={index} className="t-home__gallery-item mrt_img" data-attribute="SRL">
                  <AImage
                    src={item.image as any}
                    srcSp={item.imageSp as any}
                    srcWebp={item.imageWebp as any}
                    srcWebpSp={item.imageWebpSp as any}
                    caption={item.caption}
                    align={item.align}
                    lazy={true}
                  />
                </div>
              ))}
            </Carousel>

            <Section className="t-privacy-policy__detail newcapter" >
              <div className="t-privacy-policy__info">
                {glanceWhyQSNCC.map((policy: any, index: number) => (
                  <div key={index} className="t-privacy-policy__info-detail-holder">
                    <div
                      className="t-privacy-policy__info-detail"
                      dangerouslySetInnerHTML={{ __html: (policy.content as any)[i18n.language || 'en'] }}
                    />
                  </div>
                ))}
              </div>
            </Section>
            <div className='whyqsncc'></div>
            <div className='titleend'>QSNCC地理位置优越，拥有灵活的空间、服务和尖端技术，提供可持续发展以及安全保障。</div>
            <Section className="t-news-detail__excerpt" >
              <div className="t-privacy-policy__info">
                {glanceWhyQSNCCLocation.map((policy: any, index: number) => (
                  <div key={index} className="t-privacy-policy__info-detail-holder">
                    <div
                      className="t-news-detail__excerpt-main"
                      dangerouslySetInnerHTML={{ __html: (policy.content as any)[i18n.language || 'en'] }}
                    />
                  </div>
                ))}
              </div>
            </Section>
            <div className='all'>
              <div className='center'>
                <motion.div variants={motionVariants.projectInfoItem} className="t-home__project-info-text">

                  <div className="t-home__project-info-text-content ">
                    <Player
                      ref={videoPlayerRef}
                      muted={false}
                      playsInline={true}
                      aspectRatio={'16:9'}
                      autoPlay={false}
                      loop={false}
                    >
                      <ControlBar autoHide={false} />
                      <source src="/videos/Teaser_QSNCC.mp4" type="video/mp4" />
                    </Player>
                  </div>
                  <div className="t-home__project-info-text-video">
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('home.project-info.description.line-1'),
                        }}
                      />
                    </Text>
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: '',
                        }}
                      />
                    </Text>
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('home.project-info.description.line-2'),
                        }}
                      />
                    </Text>
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: '',
                        }}
                      />
                    </Text>
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: '',
                        }}
                      />
                    </Text>
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('home.project-info.title.line-1'),
                        }}
                      />
                    </Text>
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: '',
                        }}
                      />
                    </Text>
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: '',
                        }}
                      />
                    </Text>
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('home.project-concept.continue.description'),
                        }}
                      />
                    </Text>
                  </div>
                </motion.div>
              </div>
            </div>

            {/* <Section className="t-home__gallery">
              <div className="t-home__gallery-waypoint">
                <Waypoint onEnter={() => setShowGallery(true)} />
              </div>
              <motion.div ref={galleryRef} variants={motionVariants.gallery} initial="hidden" animate="show">
                <SimpleReactLightbox>
                  
                  <SRLWrapper
                    elements={galleryList.map((item: any) => {
                      return {
                        src: item.image,
                        thumbnail: item.imageSp,
                        caption: item.caption,
                      };
                    })}
                  />

                  <SRLWrapper>
                    <Carousel
                      mode="gallery"
                      modifiers="no-button"
                      // onSlideMove={gallerySliderMoveHandler}
                      // onTouchStart={gallerySliderMoveHandler}
                      onTouchEnd={gallerySliderChangedHandler}
                      onSlideBounce={gallerySliderChangedHandler}
                      onAutoplay={galleryAutoplayHandler}
                    >
                      {galleryList.map((item: any, index: number) => (
                        <div key={index} className="t-home__gallery-item" data-attribute="SRL">
                          <AImage
                            src={item.image as any}
                            srcSp={item.imageSp as any}
                            srcWebp={item.imageWebp as any}
                            srcWebpSp={item.imageWebpSp as any}
                            caption={item.caption}
                            align={item.align}
                            lazy={true}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </motion.div>
            </Section> */}

            <Section className="t-home__news">
              {/*<Waypoint onEnter={() => setShowNews(true)} />*/}
              <div className="t-home__news-waypoint">
                <Waypoint onEnter={() => handleSectionScrollEnter('news')} />
              </div>
              <motion.div ref={newsRef} variants={motionVariants.news} initial="hidden" animate="show">
                <motion.div className="t-home__news-title" variants={motionVariants.newsItem}>
                  <Heading>{t('home.news.title')}</Heading>
                </motion.div>

                <motion.div variants={motionVariants.newsItem}>
                  {/*
                  <Carousel mode="news-highlight">
                    {newsList.map((news: any, index: number) => {
                      return (
                        <CardItem
                          key={index}
                          image={news.image}
                          imageSp={news.imageSp}
                          imageWebp={news.imageWebp}
                          imageWebpSp={news.imageWebpSp}
                          date={news.date}
                          onClick={() => handleNewsClick(news.slug)}
                        >
                          {(news.headline as any)[i18n.language]}
                        </CardItem>
                      );
                    })}
                  </Carousel>
                  */}
                  <div className="t-media__info-news-carousel">
                    <Carousel mode="news">
                      {newsList.map((news: any, index: number) => {
                        return (
                          <NewsCard
                            key={index}
                            value={news.slug}
                            image={news.image}
                            imageSp={news.imageSp}
                            imageWebp={news.imageWebp}
                            imageWebpSp={news.imageWebpSp}
                            title={(news.headline as any)[i18n.language]}
                            isLongTitle={news.is_long_title}
                            summary={(news.topic as any)[i18n.language]}
                            onClick={handleNewsClick}
                          />
                        );
                      })}
                    </Carousel>
                  </div>


                </motion.div>
              </motion.div>
            </Section>

            <Section className="t-home__getting-here">
              {/*<Waypoint onEnter={() => setShowGettingHere(true)} />*/}
              <div className="t-home__getting-here-waypoint">
                <Waypoint onEnter={() => handleSectionScrollEnter('getting-here')} />
              </div>
              <motion.div ref={gettingHereRef} variants={motionVariants.gettingHere} initial="hidden" animate="show">
                <motion.div className="t-home__getting-here-heading" variants={motionVariants.gettingHereItem}>
                  <Heading>{t('home.getting-here.title')}</Heading>
                </motion.div>
                <motion.div className="t-home__getting-here-content" variants={motionVariants.gettingHereItem}>
                  <div className="t-home__getting-here-content-image">
                    <ImageFrame url="/images/dummy/building-1.jpg" title="楼层1" />
                  </div>
                  <div className="t-home__getting-here-content-text">
                    <Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('home.getting-here.description'),
                        }}
                      />
                    </Text>
                    <div className="t-home__getting-here-content-view">
                      {/* <ViewMore link="https://goo.gl/maps/FXxRzRCq7Ruw3rp1A" color="gold" target="_blank">
                        {t('home.getting-here.view-on-google-map')}
                      </ViewMore> */}
                      {/* 链接到百度地图 */}
                      {/* <ViewMore link="https://map.baidu.com/poi/%E8%AF%97%E4%B8%BD%E5%90%89%E7%8E%8B%E5%90%8E%E5%9B%BD%E5%AE%B6%E4%BC%9A%E8%AE%AE%E4%B8%AD%E5%BF%83/@11194278.005,1532374.74,19z?uid=0680a0e4b7af2058d12dd697&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&seckey=2e2a0a47bdcac477b497b3756246e88116d753808397929acaaf5a201554f1f535a8951ab937d219eff0ffe9855708944f6bcd8db2619f964c6113bb03b0ac033fbbfcc5712807b274dbe0e1865bdfc3ac1c147617dd3facfaffdc23745bf492f44a37ad8ff147baadfcdafc303c1e8b7aa6ae7514562d49f3062bb4f8ddaefb399d0e95f6244068788dd72c38a8aae1afd106f7b02075ee6b8117f672f5f14d905e5cee9816e18b2b72ab4f9f3b78d954b93ae3e18eacdfb16abf365586c7bf30830c114f1f5116d9c06b16acffc66049dba771f02df6367f896ed3256872e623ba2e08a5860e8c1b6eaab42aed8f15&querytype=detailConInfo&da_src=shareurl" color="gold" target="_blank">
                        {t('home.getting-here.view-on-google-map')}
                      </ViewMore> */}
                      <ViewMore link="/cn/bus-route/busroute" target="_blank">
                        {t('home.getting-here.view-on-google-map')}
                      </ViewMore>
                    </div>
                  </div>
                </motion.div>
              </motion.div>

              <div className="t-home__getting-here-tabs">
                <div className="t-home__getting-here-tabs-waypoint">
                  <Waypoint onEnter={() => setShowGettingHereTab(true)} />
                </div>
                {/*<Waypoint onEnter={() => handleSectionScrollEnter('getting-here-tabs')} />*/}
                <motion.div
                  ref={gettingHereTabRef}
                  className="t-home__getting-here-tabs-content"
                  variants={motionVariants.gettingHereTab}
                  initial="hidden"
                  animate="show"
                >
                  <GettingHere />
                </motion.div>
              </div>
            </Section>

            <Section className="t-home__map">
              <div className="t-home__map-waypoint">
                <Waypoint onEnter={() => setShowMap(true)} />
              </div>
              {/*<Waypoint onEnter={() => handleSectionScrollEnter('map')} />*/}
              <motion.div ref={mapRef} variants={motionVariants.map} initial="hidden" animate="show">
                <LocationMap
                  poiList={poiList as any}
                  transports={transportList as any}
                // onPOIClick={handlePOIClick}
                />
              </motion.div>
            </Section>
          </div>
          {/* DECORATION */}

          {/* /DECORATION */}
          <div className="t-home__subscribe-box">
            {!showHero && (
              <motion.div
                className={mapModifiers('t-home__back-to-top', stickBackToTopButton && 'stick')}
                initial={{
                  y: 100,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
              >
                <BackToTopButton
                  onClick={handleBackToTopButton}
                />
              </motion.div>
            )}
            <Waypoint onEnter={() => setStickBackToTopButton(true)} onLeave={() => setStickBackToTopButton(false)} />
            <SubscribeBox onSubmit={handleSubscribe} isSubmitting={isSubscribeLoading} />
          </div>
          {/* /CONTENT */}

          {/* FOOTER */}
          <GlobalFooter
            socials={socialList.map((socialItem, key) => (
              <GlobalFooterSocialItem key={key} type={socialItem.type as any} link={socialItem.link} />
            ))}
          />
          {/* /FOOTER */}
        </Whole>
      </main>

      {/* Video Modal */}
      <Modal opened={showVideoModal} noHeader={true} noBackground={true}>
        <div className="t-home__video-modal">
          <div className="t-home__video-modal-overlay" onClick={handleCloseVideoModal}></div>
          <div className="t-home__video-modal-player">
            {currentVideoSource && (
              <Player ref={videoPlayerRef} autoplay poster={videoPoster}>
                <BigPlayButton position="center" />
                <source src={currentVideoSource.webm} type="video/webm" />
                <source src={currentVideoSource.mp4} type="video/mp4" />
              </Player>
            )}
          </div>
        </div>
      </Modal>
      {/* /Video Modal */}

      {/* Subscribe Modal */}

      <Modal opened={showSuccessModal} noHeader={true} noCloseButton={true} noBackground={true}>
        <motion.div
          variants={motionVariants.advertisingModal}
          initial="hidden"
          animate="show"
        >
          <div className="p-thankyou-modal">
            <div className="p-thankyou-modal__content">
              <div className="p-thankyou-modal__content-button">
                <Button onClick={handleCloseThankyouModal}><Icon name={'close-sm'} color='gold'></Icon></Button>
              </div>
              <div className="p-thankyou-modal__content-logo">
                <img src="/images/qsncc-logo-wh.svg" alt="thank you" width="150" height="44" />
              </div>
              <div className="p-thankyou-modal__content-icon">
                <img src="/images/qsncc_erweima.png" alt="thank you" width="140" height="140" />
              </div>
              <p className="p-thankyou-modal__content-title">{t('contact.advertising.title')}</p>
              <p
                className="p-thankyou-modal__content-description"
                dangerouslySetInnerHTML={{ __html: t('contact.advertising.description') }}
              />
              <p
                className="p-thankyou-modal__content-description"
                dangerouslySetInnerHTML={{ __html: t('contact.advertising.time') }}
              />

            </div>
          </div>
        </motion.div>
      </Modal>

      {/* <Modal opened={showSuccessModal} noHeader={true} noCloseButton={true} noBackground={true}>
        <div className="p-thankyou-modal">
          <div className="p-thankyou-modal__overlay" onClick={handleCloseThankyouModal}></div>
          <div className="p-thankyou-modal__content">
            <div className="p-thankyou-modal__content-icon">
              <img src="/images/thankyou.svg" alt="thank you" width="140" height="140" />
            </div>
            <p className="p-thankyou-modal__content-title">{t('contact.thankyou.title')}</p>
            <p
              className="p-thankyou-modal__content-description"
              dangerouslySetInnerHTML={{ __html: t('contact.thankyou.description') }}
            />
            <div className="p-thankyou-modal__content-button">
              <Button onClick={handleCloseThankyouModal}>{t('general.close')}</Button>
            </div>
          </div>
        </div>
      </Modal> */}

      <Modal opened={showErrorModal} noHeader={true} noCloseButton={true} noBackground={true}>
        <div className="p-thankyou-modal">
          <div className="p-thankyou-modal__overlay" onClick={handleCloseErrorModal}></div>
          <div className="p-thankyou-modal__content">
            <p className="p-thankyou-modal__content-title">{t('contact.error')}</p>
            <p className="p-thankyou-modal__content-description">
              <code>{apiErrorMessage}</code>
            </p>
            <div className="p-thankyou-modal__content-button">
              <Button onClick={handleCloseErrorModal}>{t('general.close')}</Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* /Subscribe Modal */}
    </div>
  );
};

export default withRouter(Home);
