import React from 'react';
import { mapModifiers } from 'libs/component';
import { Icon } from 'components/atoms/icon';

export interface TabItemProps {
  icon?: string;
  active?: boolean;
  children?: React.ReactNode;
  bold?: boolean;
}

export const TabItem: React.FC<TabItemProps> = ({ icon, active = false, bold = true, children }) => (
  <div className={mapModifiers('a-tab-item', icon && 'icon', active && 'active', bold && 'bold')}>
    {icon && (
      <div className="a-tab-item__icon">
        <Icon name={icon} color={active ? 'gold' : 'grey'} />
      </div>
    )}
    {children}
  </div>
);
