/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { ViewMore } from 'components/atoms/view-more';
import { useTranslation } from 'react-i18next';
// import { LocationInfoItem } from 'components/atoms/location-info-item';

export interface LocationMapInfoCardTransportProps {
  type: ModifierProp<'mrt' | 'bts'>;
  name: string;
}

export interface LocalizedString {
  cn?: string;
  th?: string;
}

export interface LocationMapInfoCardProps {
  logo?: string;
  image: string;
  imageSP?: string;
  imageWebp?: string;
  imageWebpSP?: string;
  title: LocalizedString;
  description?: LocalizedString;
  tel?: string;
  hours?: string;
  map?: string;
}

export const LocationMapInfoCard: React.FC<LocationMapInfoCardProps> = ({
  logo,
  image,
  imageSP,
  imageWebp,
  imageWebpSP,
  title,
  description,
  // tel,
  // hours,
  map,
}) => {
  const { t, i18n } = useTranslation();
  const lang = useMemo(() => {
    return i18n.language || 'cn';
  }, [i18n]);

  return (
    <div className={mapModifiers('m-location-map-info-card', logo && 'logo')}>
      {logo && (
        <div className="m-location-map-info-card__logo">
          <img src={logo} alt={`${(title as any)[lang]} Logo`} />
        </div>
      )}
      <div className="m-location-map-info-card__image">
        {imageSP && (
          <picture>
            {imageWebpSP && <source media="(max-width: 640px)" srcSet={imageWebpSP} type="image/webp" />}
            {imageWebp && <source media="(min-width: 641px)" srcSet={imageWebp} type="image/webp" />}
            {imageSP && <source media="(max-width: 640px)" srcSet={imageSP} />}
            <source media="(min-width: 641px)" srcSet={image} />
            <img
              data-sizes="auto"
              data-src={image}
              alt={(title as any)[lang]}
              width="400"
              height="200"
              className="lazyload"
            />
          </picture>
        )}
        {!imageSP && <img data-sizes="auto" data-src={image} alt={(title as any)[lang]} className="lazyload" />}
      </div>
      <h3 className="m-location-map-info-card__title">{(title as any)[lang]}</h3>
      <p
        className="m-location-map-info-card__description"
        dangerouslySetInnerHTML={{
          __html: (description as any)[lang],
        }}
      />
      <div className="m-location-map-info-card__info">
        {/*<div className="m-location-map-info-card__info-list">
          {tel && (
            <LocationInfoItem type="phone">
              <a href={`tel:${tel}`}>{tel}</a>
            </LocationInfoItem>
          )}
          {hours && <LocationInfoItem type="hours">{hours}</LocationInfoItem>}
        </div> */}
        <div className="m-location-map-info-card__info-map">
          <ViewMore link={map as string} color="blue" target="_blank">
            {t('home.getting-here.view-on-google-map')}
          </ViewMore>
        </div>
      </div>
    </div>
  );
};
