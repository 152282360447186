import React, { useEffect, useRef, useState } from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { TabItem, TabItemProps } from 'components/atoms/tab-item';

export interface TabProps {
  children?: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

export const Tab: React.FC<TabProps> = ({ children, isActive, onClick }) => (
  <li className={mapModifiers('m-tabs__tab', isActive && 'active')} onClick={onClick}>
    {children}
  </li>
);

export interface TabsProps {
  modifiers?: ModifierProp<'floor'>;
  current?: number;
  children?: React.ReactNode;
  // tabs: React.ReactNodeArray;
  tabs: TabItemProps[];
  onChange?: (index: number) => void;
}

export const Tabs: React.FC<TabsProps> = ({ modifiers, current, tabs, children, onChange }) => {
  const [activeIndex, setActiveIndex] = useState(current);
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setActiveIndex(current);
  }, [current]);

  useEffect(() => {
    tabsRef.current?.style?.setProperty('--length', tabs.length.toString());
  }, [tabs.length]);

  useEffect(() => {
    if (Number(activeIndex) > -1) {
      tabsRef.current?.style?.setProperty('--active-index', `${activeIndex}`);
    }
  }, [activeIndex]);

  return (
    <div className={mapModifiers('m-tabs', modifiers)} ref={tabsRef}>
      <div className="m-tabs__tab-header">
        <ul className="m-tabs__tab-list">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              isActive={index === activeIndex}
              onClick={() => {
                setActiveIndex(index);
                onChange && onChange(index);
              }}
            >
              <TabItem key={index} icon={tab.icon} active={index === activeIndex} bold={tab.bold}>
                {tab.children}
              </TabItem>
            </Tab>
          ))}
        </ul>
        <div className={mapModifiers('m-tabs__indicator', !(Number(activeIndex) > -1) && 'disabled')} />
      </div>
      <div className="m-tabs__contents">
        {React.Children.map(children, (child, idx) => (
          <div className={mapModifiers('m-tabs__content', activeIndex === idx && 'active')}>{child}</div>
        ))}
      </div>
    </div>
  );
};
