
import { mapModifiers } from 'libs/component';
import React from 'react';


export interface ImageFrameProps {
  url: string;
  title: string;
}

export const ImageFrame: React.FC<ImageFrameProps> = ({url,title}) => (

  <div className={mapModifiers('a-image-frame')}>
    <div className="a-image-frame__frame"></div>
    <div
      className="a-image-frame__container"
      style={{
        // backgroundImage: `url(${url})`,
        backgroundImage: 'url(/images/home/gallery/whytoqsncc.png)',
      }}
    >
      <img data-sizes="auto" data-src={url} alt={title} className="lazyload" />
    </div>
    <div className='a-image-text'>
      
    </div>
  </div>

);
