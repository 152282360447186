/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { mapModifiers } from 'libs/component';

const markerImg = '/images/marker-lg.svg';

export interface LocationMapMarkerProps {
  children?: React.ReactNode;
  active?: boolean;
  onClick?: (active: boolean) => void;
}

export const LocationMapMarker: React.FC<LocationMapMarkerProps> = ({ children, active = false, onClick }) => {
  const [isActive, setIsActive] = useState(active);

  const handleClick = useCallback(() => {
    const newState = !isActive;
    setIsActive(newState);
    if (onClick) {
      onClick(newState);
    }
  }, [isActive, onClick]);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <div className={mapModifiers('a-location-map-marker', isActive && 'active')}>
      <button aria-label="marker" type="button" className="a-location-map-marker__button" onClick={handleClick}>
        <div className="a-location-map-marker__icon">
          <img
            data-sizes="auto"
            data-src={markerImg}
            alt="marker"
            className="a-location-map-marker__icon-image lazyload"
            width="182"
            height="230"
          />
          <div className="a-location-map-marker__logo">{children}</div>
        </div>
      </button>
    </div>
  );
};
