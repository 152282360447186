/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export interface NewsCardProps {
  value: string;
  image: string;
  imageSp: string;
  imageWebp: string;
  imageWebpSp: string;
  title: string;
  summary?: string;
  modifiers?: ModifierProp<'large'>;
  isLongTitle?: boolean;
  onClick?: (value: string) => void;
}

export const NewsCard: React.FC<NewsCardProps> = ({
  value,
  image,
  imageSp,
  imageWebp,
  imageWebpSp,
  title,
  summary,
  modifiers,
  isLongTitle = false,
  onClick,
}) => {
  const summaryRef = useRef(null);
  const currentEllipsis = useRef(undefined);
  const currentTextEllipsis = useRef(undefined);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    async function loadLib() {
      const lib = await import('ftellipsis');
      currentEllipsis.current = lib.default;
    }

    if (window && !currentEllipsis.current) {
      loadLib().then(() => {
        setIsLoad(true);
      });
    } else if (window && currentEllipsis.current) {
      setIsLoad(true);
    }
  }, [currentEllipsis]);

  const handleResize = useCallback(() => {
    if (currentTextEllipsis.current) {
      const te = currentTextEllipsis.current as any;
      te.unset();
      te.calc();
      te.set();
    }
  }, [currentTextEllipsis]);

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize);
      }
    };
  });

  useEffect(() => {
    if (currentEllipsis.current && isLoad) {
      const Ellipsis = currentEllipsis.current as any;
      const te = new Ellipsis(summaryRef.current);
      currentTextEllipsis.current = te;
      te.calc();
      te.set();
      handleResize();

      return () => {
        te.unset();
        te.destroy();
      };
    }
    return undefined;
  }, [isLoad, currentEllipsis, handleResize]);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(value);
    }
  }, [onClick, value]);

  return (
    <div className={mapModifiers('a-news-card', modifiers, isLongTitle && 'long-title')} onClick={handleOnClick}>
      <div className="a-news-card__top">
        <figure className="a-news-card__image">
          {image && (
            <picture>
              {imageSp && (
                <>
                  <source media="(max-width: 640px)" srcSet={imageWebpSp} type="image/webp" />
                  <source media="(min-width: 641px)" srcSet={imageWebp} type="image/webp" />
                  <source media="(max-width: 640px)" srcSet={imageSp} />
                  <source media="(min-width: 641px)" srcSet={image} />
                </>
              )}
              <img data-src={image} alt={title as string} className="lazyload" width="309" height="232" />
            </picture>
          )}
        </figure>
      </div>
      <div className="a-news-card__bottom">
        <div
          className="a-news-card__title"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {summary && (
          <div ref={summaryRef} className="a-news-card__summary">
            <p
              className="a-news-card__summary-text"
              dangerouslySetInnerHTML={{
                __html: summary,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
