/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { Logo } from 'components/atoms/logo';

const markerIcon = '/images/marker.svg';

export interface LocationMapListItemProps {
  type: ModifierProp<'number' | 'logo'>;
  name: string;
  value?: string;
  children?: React.ReactNode;
  onClick?: (value: string) => void;
}

export const LocationMapListItem: React.FC<LocationMapListItemProps> = ({ children, type, name, value, onClick }) => {
  const handleClick = useCallback(
    value => {
      if (onClick) {
        onClick(value);
      }
    },
    [onClick]
  );

  return (
    <div className={mapModifiers('a-location-map-list-item', type, onClick && 'action')}>
      <button
        aria-label="marker"
        className="a-location-map-list-item__button"
        type="button"
        onClick={() => handleClick(value)}
      >
        <div className="a-location-map-list-item__icon">
          {(type as string) === 'logo' && (
            <i className="a-location-map-list-item__icon-bg">
              <Logo name={name} />
            </i>
          )}
          {(type as string) === 'number' && (
            <>
              <i className="a-location-map-list-item__icon-bg">
                <img data-src={markerIcon} alt="marker icon" className="lazyload" />
              </i>
              <span className="a-location-map-list-item__icon-text">{name}</span>
            </>
          )}
        </div>
        <div className="a-location-map-list-item__text">{children}</div>
      </button>
    </div>
  );
};
