export function supportFormatWebp() {
  if (typeof document !== 'undefined') {
    const elem = document.createElement('canvas') as HTMLCanvasElement;

    if (elem.getContext && elem.getContext('2d')) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    } else {
      // very old browser like IE 8, canvas not supported
      return false;
    }
  }
  return false;
}

export function isSafari() {
  let isValid = false;
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') === -1) {
        isValid = true;
      }
    }
  }
  return isValid;
}

export const isRetina = () => {
  const mediaQuery =
    '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)';

  if (typeof window !== 'undefined' && window.devicePixelRatio > 1) {
    return true;
  }

  if (typeof window !== 'undefined' && window.matchMedia && window.matchMedia(mediaQuery).matches) {
    return true;
  }

  return false;
};
